<template>
  <section>

    <v-fade-transition mode="out-in">
      <div v-if="authorised === true">
        <router-view v-if="!reload"></router-view>
      </div>
      <div v-else>
        <Unauthorised></Unauthorised>
      </div>
    </v-fade-transition>

  </section>
</template>

<script>
import Unauthorised from "@/components/Library/Unauthorised"
export default {
  name: "News",
  components: {
    Unauthorised
  },
  watch: {
    "$route" () {
      this.reload = true

      this.$nextTick().then(() => {
        // Add the component back in
        this.reload = false;
      });
    }
  },
  data: () => {
    return {
      authorised: false,
      reload: false
    }
  },
  mounted() {
    console.log("News mounted");
    this.$getCurrentUserGroups().subscribe((userGroups) => {
      if(userGroups.includes("Administrator") || userGroups.includes("News")){
        this.authorised = true
      } else {
        this.authorised = false
      }
    }, 
    (err)=> {
       this.authorised = false
       console.log(err)
    });
  }
}
</script>
